import { useSnackbarStore } from '@/stores/snackbar'
import {
  CarrierRequestType,
  ContainerType,
  DocumentType,
  DriverStatus,
  FreightStatusCode,
  type DriverBase,
  type Phone
} from '@/types'
import { DeparturePermissionType, FootsType, OverweightType } from '@/types/Vehicle'
import type { DriverDTO } from '@/types/api/Driver/DriverDTO'
import type { DriverDetailedDTO } from '@/types/api/Driver/DriverDetailedDTO'

export const getDriverFullName = (driver: DriverBase | DriverDTO | DriverDetailedDTO) => {
  const secondName = driver.secondName ? ` ${driver.secondName}` : ''
  return `${driver.lastName || ''} ${driver.firstName || ''}${secondName}`
}

export const getContainerTypeTranslation = (containerType: ContainerType) => {
  switch (containerType) {
    case ContainerType.CONTAINER_20DC:
      return '20DC'
    case ContainerType.CONTAINER_40DC:
      return '40DC'
    case ContainerType.CONTAINER_40HC:
      return '40HC'
    case ContainerType.CONTAINER_40RF:
      return '40RF'
  }
}

export const getDocumentTypeTranslation = (documentType: DocumentType) => {
  switch (documentType) {
    case DocumentType.RUSSIAN_PASSPORT:
      return 'Российский паспорт'
    case DocumentType.FOREIGN_PASSPORT:
      return 'Иностранный паспорт'
  }
}
export const isFreightDelayed = (toTime: string, toTimeEstimated: string) => {
  const toTimeDate = new Date(toTime)
  const toTimeEstimatedDate = new Date(toTimeEstimated)
  return toTimeDate.getTime() < toTimeEstimatedDate.getTime()
}

export const getFreightStatusColorMapping = (
  status: FreightStatusCode
): 'labelPrimary' | 'secondary' | 'success' | 'warning' => {
  switch (status) {
    case FreightStatusCode.NEW:
    case FreightStatusCode.CANCELLED:
      return 'secondary'
    case FreightStatusCode.RECOMMENDED:
      return 'labelPrimary'
    case FreightStatusCode.FINISHED:
      return 'success'
    default:
      return 'warning'
  }
}

export const convertDateToISO = (date: string | number) => {
  return new Date(date).toISOString()
}

export const setDateToStart = (date: string | number) => {
  const dateStart = new Date(date)
  dateStart.setHours(0, 0, 0, 0)
  return dateStart
}

export const setDateToEnd = (date: string | number) => {
  const dateEnd = new Date(date)
  dateEnd.setHours(23, 59, 59, 999)
  return dateEnd
}

export const filterRequests = (value: string, query: string) => {
  /**
   * TODO: use request types once they are done
   */
  const valueMap: Record<string, string> = {
    CREATE: 'Добавление',
    UPDATE: 'Изменение',
    DELETE: 'Удаление',
    NEW: 'Создана',
    REJECTED: 'Отклонена',
    APPROVED: 'Одобрена'
  }

  const mappedValue =
    valueMap[value]?.toString().toLocaleLowerCase() || value.toString().toLocaleLowerCase()
  return mappedValue.includes(query?.toString().toLocaleLowerCase() || '')
}

export const copyToClipboard = (text: string): string | void => {
  try {
    navigator.clipboard.writeText(text)
  } catch (e) {
    console.warn('Copy to clipboard failed: ', e)
  }
}

export const mapDriverStatus = (status: DriverStatus): string => {
  switch (status) {
    case DriverStatus.ON_LINE:
      return 'На линии'
    case DriverStatus.NOT_ON_LINE:
      return 'Не на линии'
  }
}

export const getDeparturePermissionTypeTranslation = (type: DeparturePermissionType): string => {
  switch (type) {
    case DeparturePermissionType.NONE:
      return 'Нет'
    case DeparturePermissionType.MKAD:
      return 'МКАД'
    case DeparturePermissionType.TTK:
      return 'ТТК'
    case DeparturePermissionType.SK:
      return 'CK'
  }
}

export const getFootsTypeTranslation = (type: FootsType): string => {
  switch (type) {
    case FootsType.FOOTS_40:
      return '40'
    case FootsType.FOOTS_40_20:
      return '40/20'
    case FootsType.FOOTS_CONSTRUCTOR:
      return 'Конструктор'
  }
}

export const getOverweightTypeTranslation = (type: OverweightType): string => {
  switch (type) {
    case OverweightType.WEIGHT_20:
      return '20'
    case OverweightType.WEIGHT_26:
      return '26'
    case OverweightType.WEIGHT_28:
      return '28'
    case OverweightType.WEIGHT_30:
      return '30'
  }
}

export const mapPhoneNumberToPhoneType = (phoneNumber: string): Phone => {
  return {
    code: '+7',
    number: phoneNumber
      .split(/[- ()]/)
      .join('')
      .slice(1)
  }
}

export const showErrorMessage = (message: string) => {
  const snackbarStore = useSnackbarStore()
  snackbarStore.openSnackbar({
    color: 'red',
    message: message
  })
}

export const getRequestTypeTranslation = (type: CarrierRequestType): string => {
  switch (type) {
    case CarrierRequestType.CREATE:
      return 'Добавление перевозчика'

    case CarrierRequestType.UPDATE:
      return 'Обновление перевозчика'

    case CarrierRequestType.DELETE:
      return 'Удаление перевозчика'
  }
}
export const buildPhone = (phoneNumber: string) => {
  const phone = {
    code: '+7',
    number: phoneNumber
      .split(/[- ()]/)
      .join('')
      .slice(1)
  }
  return phone
}
